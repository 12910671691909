// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Local
  //  URL_SERVICE: 'http://localhost:8080/WSRestFECWEB/EBSRest',///---------- LOCALMENTE
   URL_SERVICE: 'https://tspincfecbe.ebsproductodigital.com/WSRestFECWEB/EBSRest',///-------------PRUEBAS
  //  URL_SERVICE: 'https://tspincfecbe.extractoweb.com/WSRestFECWEB/EBSRest',///-------------PRUEBAS

  //Pruebas
 //URL_SERVER: 'http://129.153.131.2:81',
//URL_SERVICE: 'http://129.213.50.143:8081/WSRestFECWEB/EBSRest'

  //Produccion
  // URL_SERVICE: 'https://ebsappweb.com:8443/WSRestFec/API/WSRestAPI/', // Produccion
  // URL_SERVICE: 'https://fecbe.ebscreditodigital.com/WSRestFECWEB/EBSRest',
  //  URL_SERVICE: 'https://tspincfecbe.extractoweb.com/WSRestFECWEB/EBSRest',

  //Servidor FTP
  //URL_FTP: 'https://apps.ebscreditodigital.com/FEC/',

};

// ng build --output-hashing=all --prod --base-href /     //COMANDO PARA PRODUCCION DE PINC

// ng build --output-hashing=all --base-href /FecPinc/  //COMANDO PARA SUBIR A PRUEBAS

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
