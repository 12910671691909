<app-spinner *ngIf="loading"></app-spinner>
<p-confirmDialog #cd>
  <button type="button" pButton (click)="cd.accept()"></button>
  <button type="button" pButton (click)="cd.reject()"></button>
</p-confirmDialog>

<p-menubar [model]="items">
  <ng-template pTemplate="start">
    <!-- <img src="../../../assets/iconos/logo.png" class="p-mr-2 imgLogo" /> -->
    <div class="imgLogo"></div>
  </ng-template>
  <!-- <ng-template pTemplate="end">
    <span class="p-overlay-badge p-mr-6">
      <a
        class="pi pi-bell text-secondary"
        style="font-size: 1.5em; cursor: pointer"
      ></a>
    </span>
    
    <i
      class="pi pi-sign-out"
      style="font-size: 1.5rem; cursor: pointer"
    ></i>
  </ng-template> -->
</p-menubar>
