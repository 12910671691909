import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CargaInfoService } from '../../../src/app/service/carga-info-service.service';

@Injectable({
  providedIn: 'root',
})
export class TimeOutService implements OnDestroy {
  private inactivityTimeout: number;
  private inactivityInterval: any;
  private subscriptions: Subscription[] = [];
  private isRequestInProgress: boolean = false

  constructor(
    private parametroTimeOut: CargaInfoService,
    private router: Router
  ) {
    this.startTimer();
  }

  private startTimer() {
    this.resetTimer();

    // RxJS event bindings
    this.subscriptions.push(
      fromEvent(window, 'mousemove')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetTimer()),
      fromEvent(window, 'keypress')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetTimer()),
      fromEvent(window, 'mousedown')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetTimer()),
      fromEvent(window, 'touchstart')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetTimer()),
      fromEvent(window, 'click')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetTimer()),
      fromEvent(window, 'scroll')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetTimer())
    );
  }

  private resetTimer() {
    clearTimeout(this.inactivityInterval);
    const param = localStorage.getItem('inactivityTimeout');
    if (param == null || param == undefined) {
      this.fetchTimeoutParameter();
    } else {
      this.inactivityTimeout = parseInt(param, 10);
      this.inactivityInterval = setTimeout(
        this.logout,
        this.inactivityTimeout * 60 * 1000
      );
    }
  }

  private fetchTimeoutParameter() {
    if (!this.isRequestInProgress) { // Verificar si hay una petición en curso
      this.isRequestInProgress = true; // Marcar que hay una petición en curso

      this.parametroTimeOut.parametroTimeOut().subscribe(
        (data: string) => {
          const parametroNumber: number = parseInt(data, 10);
          localStorage.setItem('inactivityTimeout', parametroNumber.toString());
          if (parametroNumber != null) {
            this.inactivityTimeout = parametroNumber;
            this.inactivityInterval = setTimeout(
              this.logout,
              parametroNumber * 60 * 1000
            );
          } else {
            console.error('Puedo ingresar a la parametrizacion de la base de datos');
            this.inactivityTimeout = 5;
            this.inactivityInterval = setTimeout(
              this.logout,
              5 * 60 * 1000
            );
          }
          this.isRequestInProgress = false; // Marcar que la petición ha finalizado
        },
        (error) => {
          console.error(
            'Error al obtener el parámetro de tiempo de inactividad:',
            error
          );
          this.isRequestInProgress = false; // Marcar que la petición ha finalizado
        }
      );
    }
  }

  resetCounter() {
    clearTimeout(this.inactivityInterval);
    this.startTimer();
  }

  logout = () => {
    alert('Su sesión ha expirado por inactividad');
    this.router.navigate(['/firma-digital/iniciarSesion']);
  };

  ngOnDestroy() {
    clearTimeout(this.inactivityInterval);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
