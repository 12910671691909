import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CargaInfoService {

  public url = environment.URL_SERVICE;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  }

  tipoPerfil(){
    let path =this.url + 'WSAdminCore/tipoPerfil';
    return this.http.post(path, { headers: this.headers });
  }

  tipoPago(){
    let path =this.url + 'WSAdminCore/tipoPago';
    return this.http.post(path, { headers: this.headers });
  }

  tipoPerfilEmpleados(){
    let path =this.url + 'WSAdminCore/tipoPerfilEmpleados';
    return this.http.post(path, { headers: this.headers });
  }

  tipoDocumento(){
    let path =this.url + 'WSAdminCore/tiposIden';
    return this.http.post(path, { headers: this.headers });
  }

  tipoPQRSF(){
    return this.http.get(`${this.url}WSPQRSF/tipoPQRSF`, { headers: this.headers });
  }

  listaProfesiones(){
    let path =this.url + 'WSAdminCore/getProfesiones';
    return this.http.post(path, { headers: this.headers });
  }

  listaCargos(){
    let path =this.url + 'WSAdminCore/getCargos';
    return this.http.post(path, { headers: this.headers });
  }

  listaEntidades(){
    let path =this.url + 'WSAdminCore/entidadesBancarias';
    return this.http.post(path, { headers: this.headers });
  }


  // Para pagarés ---------------------------
  tipoDocumentos(){
    let path =this.url + '/CargaDatosPinc/tiposIden';
    return this.http.get(path, { headers: this.headers });
  }
  parametroTimeOut() {
    const url = `${this.url}/GestionPinc/ParametrosbyName`;
    const params = new HttpParams().set('cNombre', 'TIME_OUT');

    return this.http.get(url, { params: params, headers: this.headers });
  }

  tipoPerfiles(){
    let path =this.url + '/CargaDatosPinc/tipoPerfil';
    return this.http.get(path, { headers: this.headers });
  }
  tipoPerfilCliente(){
    let path =this.url + '/CargaDatosPinc/tipoPerfilCliente';
    return this.http.get(path, { headers: this.headers });
  }
  getParentesco(){
    let path =this.url + '/CargaDatosPinc/parentesco';
    return this.http.get(path, { headers: this.headers });
  }
  getGenero(){
    let path =this.url + '/CargaDatosPinc/genero';
    return this.http.get(path, { headers: this.headers });
  }
  getEstadoCivil(){
    let path =this.url + '/CargaDatosPinc/estadoCivil';
    return this.http.get(path, { headers: this.headers });
  }
  getCiudades(){
    let path =this.url + '/CargaDatosPinc/ciudades';
    return this.http.get(path, { headers: this.headers });
  }

  buscarPersonaPinc(tipoIdent: string,ident: string ){
    let path =this.url + '/PersonaPinc/findPersonaByTpoIdentIdent';
    path += "?xiTipoIdent=" + tipoIdent;
    path += "&xcIdent=" + ident;
    let headerss = new HttpHeaders(
      {
        'Content-Type': 'application/json',
      }
    );
    return this.http.get(path, { headers: headerss });
  }

  //------------------------------
}
